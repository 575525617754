body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
.container {
  overflow-y: scroll;
  width:44vw;
  height:14vw;
}*/

.container_contacts {
  overflow-y: auto;
  height:8pc
}


.container {
  overflow-y: auto;
  width:38.5vw;
  height:12pc
}

.mobcontainer {
  overflow-y: auto;
  width:92.5vw;
  height:12pc
}


.container_carr_totpraders {
  overflow-y: auto;
  width:38.5vw;
  }

  .mb_container_carr_totpraders {
    overflow-y: auto;
    width:85.5vw;
    }

.container_nopremium {
  overflow-y: auto;
  width:38.5vw;
  height:15pc
}

.mobcontainer_nopremium {
  overflow-y: auto;
  width:92.5vw;
  height:15pc
}

.container_trackrecord {
  overflow-y: auto;
  max-height: 55pc;
  min-height: 25pc;
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost ::-moz-scrollbar {
  display: none;
 
}

.scrollhost {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}