/*.App {
  font-family: sans-serif;
  text-align: center;
  width: 600px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}*/


.App {
  font-family: sans-serif;
  text-align: center;
  //width: "400px";
  height: "full";
  //height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}




ul{
  display: flex;
  list-style: none;
  //max-width: 100%;
  //height: 100%;
   
  //background:#FF61F6;
  //align-items: center;
  //justify-content: center;
  overflow-x: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
  
  li{
    background:#FF61F6;
    color: #fff;
    padding: 8px;
   // border-radius: 4px;
   //margin: 0 4px;
    white-space: nowrap;
  }
}

